import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'

import { MapService, ToastService } from '@app/services'
import { Wishmap } from '@app/models'

@Component({
    selector: 'numerology-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
    mapId: string
    mapLoading: boolean = true
    map: Wishmap
    queueText: string = null
    btnLoading: boolean = false
    print: boolean = false

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private mapService: MapService,
        private toastService: ToastService
    ) {
    }

    ngOnInit(): void {
        this.checkMap()
    }

    checkMap() {
        let routeMapId = this.activatedRoute.snapshot.params.id

        this.mapLoading = true

        if (routeMapId) {
            this.mapId = routeMapId
            localStorage.setItem('mapId', routeMapId)
            this.router.navigate(['/map'])
            this.getMap(routeMapId)
        } else {
            let storageMapId = localStorage.getItem('mapId')

            if(storageMapId) {
                this.mapId = storageMapId
                this.getMap(storageMapId)
            } else {
                this.toastService.showError('Карта желаний не найдена', 'Ошибка')
                localStorage.removeItem('mapId')
                this.router.navigate(['/'])
            }
        }
    }

    getMap(mapId: string) {
        this.mapService
            .getMap(mapId)
            .subscribe(map => {
                this.map = map
                this.mapLoading = false
                this.fillMapWithImages()

                if(map.status === 10) {
                    this.mapService
                        .downloadMap(this.mapId, this.print)
                        .subscribe(r => {
                            this.queueText = r['queue']
                        })
                }
            }, err => {
                if (err.status === 402) {
                    this.toastService.showError('Оплата по карте не найдена', 'Ошибка')
                    localStorage.removeItem('mapId')
                    this.router.navigate(['/'])
                }
                if (err.status === 404) {
                    this.toastService.showError('Карта желаний не найдена', 'Ошибка')
                    localStorage.removeItem('mapId')
                    this.router.navigate(['/'])
                }
            })
    }

    checkMapIsFilled() {
        if(this.map) {
            return this.map.sectors.every(v => v.is_filled === true)
        }
    }

    mapFillStatus() {
        let fill = 0

        if(this.map) {
            this.map.sectors.forEach(el => {
                if (el.is_filled) {
                    fill = fill + 1
                }
            })
        }

        return Math.round(100 * fill / 9)
    }

    fillMapWithImages() {
        this.map.sectors.forEach(x => {
            let sectorImages = [
                { number: 4, image: 'money' },
                { number: 9, image: 'crown' },
                { number: 2, image: 'love' },
                { number: 3, image: 'family' },
                { number: 5, image: 'health' },
                { number: 7, image: 'kids' },
                { number: 8, image: 'knowledge' },
                { number: 1, image: 'career' },
                { number: 6, image: 'travel' }
            ]

            x['sector_img'] = sectorImages.find(i => i.number === x.number).image
        })
    }

    logout() {
        this.toastService.showInfo('', 'Вы вышли из системы')
        localStorage.removeItem('mapId')
        return this.router.navigate(['/'])
    }

    downloadMap(e: Event) {
        e.preventDefault()

        this.btnLoading = true

        this.mapService.downloadMap(this.mapId, this.print)
            .subscribe(r => {
                if(this.map.status === 50) {
                    let l = r['url']

                    const agent = window.navigator.userAgent.toLowerCase()

                    if (agent.indexOf('chrome') > -1 && !!(<any>window).chrome) {
                        let link = document.createElement('a')
                        link.href = l
                        link.target = '_blank'
                        link.click()
                    } else {
                        window.location = l
                    }
                    this.btnLoading = false
                } else {
                    this.checkMap()
                    this.btnLoading = false
                }
            })
    }
}