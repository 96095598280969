<main class="privacy">
    <div class="privacy-back" (click)="goBack()">
        <ion-icon class="privacy-back__icon" name="arrow-back"></ion-icon>
        <span class="privacy-back__text">Вернуться назад</span>
    </div>

    <div class="container">
        <h1 class="privacy__title">
            Политика в отношении обработки персональных данных
        </h1>

        <div class="privacy-item">
            <span class="privacy-item__head">
                1. Общие положения
            </span>
            <p class="privacy-item__lead">
                Настоящая политика обработки персональных данных составлена в соответствии с требованиями Закона Республики Казахстан от 21 мая 2013 года № 94-V «О персональных данных и их защите» и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных, предпринимаемые ИП «Numerology.Dina» (далее – Оператор).
            </p>
            <p class="privacy-item__lead">
                <strong>1.1.</strong> Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.
            </p>
            <p class="privacy-item__lead">
                <strong>1.2.</strong> Настоящая политика Оператора в отношении обработки персональных данных (далее – Политика) применяется ко всей информации, которую Оператор может получить о пользователях настоящего приложения.
            </p>
        </div>

        <div class="privacy-item">
            <span class="privacy-item__head">
                2. Основные понятия, используемые в Политике
            </span>

            <p class="privacy-item__lead">
                <strong>2.1. </strong>Автоматизированная обработка персональных данных – обработка персональных данных с помощью средств вычислительной техники;
            </p>
            <p class="privacy-item__lead">
                <strong>2.2. </strong>Блокирование персональных данных – временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных);
            </p>
            <p class="privacy-item__lead">
                <strong>2.3. </strong>Веб-сайт – совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет
            </p>
            <p class="privacy-item__lead">
                <strong>2.4. </strong>Информационная система персональных данных — совокупность содержащихся в базах данных персональных данных, и обеспечивающих их обработку информационных технологий и технических средств;
            </p>
            <p class="privacy-item__lead">
                <strong>2.5. </strong>Обезличивание персональных данных — действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных;
            </p>
            <p class="privacy-item__lead">
                <strong>2.6. </strong>Обработка персональных данных – любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных;
            </p>
            <p class="privacy-item__lead">
                <strong>2.7. </strong>Оператор – государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными;
            </p>
            <p class="privacy-item__lead">
                <strong>2.8. </strong>Персональные данные – любая информация, относящаяся прямо или косвенно к определенному или определяемому Пользователю;
            </p>
            <p class="privacy-item__lead">
                <strong>2.9. </strong>Предоставление персональных данных – действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц;
            </p>
            <p class="privacy-item__lead">
                <strong>2.10.</strong> Распространение персональных данных – любые действия, направленные на раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом;
            </p>
            <p class="privacy-item__lead">
                <strong>2.11.</strong> Трансграничная передача персональных данных – передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу;
            </p>
            <p class="privacy-item__lead">
                <strong>2.12.</strong> Уничтожение персональных данных – любые действия, в результате которых персональные данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной системе персональных данных и (или) уничтожаются материальные носители персональных данных.
            </p>
        </div>

        <div class="privacy-item">
            <span class="privacy-item__head">
                3. Оператор может обрабатывать следующие персональные данные Пользователя
            </span>

            <p class="privacy-item__lead">
                <strong>3.1.</strong> Фамилия, имя, отчество;
            </p>
            <p class="privacy-item__lead">
                <strong>3.2.</strong> Электронный адрес;
            </p>
            <p class="privacy-item__lead">
                <strong>3.3.</strong> Номера телефонов;
            </p>
            <p class="privacy-item__lead">
                <strong>3.4.</strong> Год, месяц, дата и место рождения;
            </p>
            <p class="privacy-item__lead">
                <strong>3.5.</strong> Также на сайте происходит сбор и обработка обезличенных данных о посетителях (в т.ч. файлов «cookie») с помощью сервисов интернет-статистики (Яндекс.Метрика и Google Analytics и других).
            </p>
            <p class="privacy-item__lead">
                <strong>3.6.</strong> Вышеперечисленные данные далее по тексту Политики объединены общим понятием Персональные данные.
            </p>
        </div>

        <div class="privacy-item">
            <span class="privacy-item__head">
                4. Цели обработки персональных данных
            </span>

            <p class="privacy-item__lead">
                <strong>4.1.</strong> Цель обработки персональных данных Пользователя — предоставление доступа Пользователю к сервисам, информации и/или материалам, содержащимся на веб-сайте.
            </p>
            <p class="privacy-item__lead">
                <strong>4.2.</strong> Также Оператор имеет право направлять Пользователю уведомления о новых продуктах и услугах, специальных предложениях и различных событиях. Пользователь всегда может отказаться от получения информационных сообщений, направив Оператору письмо на адрес электронной почты <a href="mailto:abuse@numerology.kz">abuse@numerology.kz</a> с пометкой «Отказ от уведомлений о новых продуктах и услугах и специальных предложениях».
            </p>
            <p class="privacy-item__lead">
                <strong>4.3.</strong> Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики, служат для сбора информации о действиях Пользователей на сайте, улучшения качества сайта и его содержания.
            </p>
        </div>

        <div class="privacy-item">
            <span class="privacy-item__head">5. Правовые основания обработки персональных данных</span>

            <p class="privacy-item__lead">
                <strong>5.1.</strong> Оператор обрабатывает персональные данные Пользователя только в случае их заполнения и/или отправки Пользователем самостоятельно через специальные формы, расположенные на сайте Оператора. Заполняя соответствующие формы и/или отправляя свои персональные данные Оператору, Пользователь выражает свое согласие с данной Политикой.
            </p>
            <p class="privacy-item__lead">
                <strong>5.2.</strong> Оператор обрабатывает обезличенные данные о Пользователе в случае, если это разрешено в настройках браузера Пользователя (включено сохранение файлов «cookie» и использование технологии JavaScript).
            </p>
        </div>

        <div class="privacy-item">
            <span class="privacy-item__head">
                6. Порядок сбора, хранения, передачи и других видов обработки персональных
                данных
            </span>

            <p class="privacy-item__lead">
                Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем реализации правовых, организационных и технических мер, необходимых для выполнения в полном объеме требований действующего законодательства в области защиты персональных данных.
            </p>

            <p class="privacy-item__lead">
                <strong>6.1.</strong> Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц.
            </p>
            <p class="privacy-item__lead">
                <strong>6.2.</strong> Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам, за исключением случаев, связанных с исполнением действующего законодательства.
            </p>
            <p class="privacy-item__lead">
                <strong>6.3.</strong> В случае выявления неточностей в персональных данных, Пользователь может актуализировать их самостоятельно, путем направления Оператору уведомление на адрес электронной почты Оператора <a href="mailto:abuse@numerology.kz">abuse@numerology.kz</a> с пометкой «Актуализация персональных данных».
            </p>
            <p class="privacy-item__lead">
                <strong>6.4.</strong> Срок обработки персональных данных является неограниченным. Пользователь может в любой момент отозвать свое согласие на обработку персональных данных, направив Оператору уведомление посредством электронной почты на электронный адрес Оператора <a href="mailto:abuse@numerology.kz">abuse@numerology.kz</a> пометкой «Отзыв согласия на обработку персональных данных».
            </p>
        </div>

        <div class="privacy-item">
            <span class="privacy-item__head">
                7. Трансграничная передача персональных данных
            </span>

            <p class="privacy-item__lead">
                <strong>7.1.</strong> Оператор до начала осуществления трансграничной передачи персональных данных обязан убедиться в том, что иностранным государством, на территорию которого предполагается осуществлять передачу персональных данных, обеспечивается надежная защита прав субъектов персональных данных.
            </p>
            <p class="privacy-item__lead">
                <strong>7.2.</strong> Трансграничная передача персональных данных на территории иностранных государств, не отвечающих вышеуказанным требованиям, может осуществляться только в случае наличия согласия в письменной форме субъекта персональных данных на трансграничную передачу его персональных данных и/или исполнения договора, стороной которого является субъект персональных данных.
            </p>
        </div>

        <div class="privacy-item">
            <span class="privacy-item__head">8. Заключительные положения</span>

            <p class="privacy-item__lead">
                <strong>8.1.</strong> Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его персональных данных, обратившись к Оператору с помощью электронной почты <a href="mailto:abuse@numerology.kz">abuse@numerology.kz</a>.
            </p>
            <p class="privacy-item__lead">
                <strong>8.2.</strong> В данном документе будут отражены любые изменения политики обработки персональных данных Оператором. Политика действует бессрочно до замены ее новой версией.
            </p>
            <p class="privacy-item__lead">
                <strong>8.3.</strong> Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу <a target="_blank" rel="nofollow" href="https://wish.numerology.kz/legal/privacy">https://wish.numerology.kz/legal/privacy</a>.
            </p>
        </div>
    </div>
</main>