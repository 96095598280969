import { Injectable } from '@angular/core'
import { ToastrService } from 'ngx-toastr'

@Injectable({
    providedIn: 'root'
})
export class ToastService {
    constructor(
        private toastr: ToastrService,
    ) { }

    showSuccess(message: string, title: string, timeOut: number = 5000) {
        this.toastr
            .success(message, title, {
                closeButton: false,
                timeOut: timeOut,
            })
    }

    showError(message: string, title: string, timeOut: number = 7000) {
        this.toastr
            .error(message, title, {
                closeButton: false,
                timeOut: timeOut,
            })
    }

    showWarning(message: string, title: string, timeOut: number = 5000) {
        this.toastr
            .warning(message, title, {
                closeButton: false,
                timeOut: timeOut,
            })
    }

    showInfo(message: string, title: string, timeOut: number = 5000) {
        this.toastr
            .info(message, title, {
                closeButton: false,
                timeOut: timeOut,
            })
    }
}