import { Component, ElementRef, OnInit } from '@angular/core'

@Component({
    selector: 'numerology-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    constructor(
        private e: ElementRef
    ) {
        this.sayHelloWorld()
    }

    ngOnInit() : void {
        this.e.nativeElement.removeAttribute('ng-version')
    }

    sayHelloWorld(): void {
    }
}
