import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { Observable } from 'rxjs'

import { environment as env } from '@env/environment'
import { Wishmap, Sector } from '@app/models'

@Injectable({
    providedIn: 'root'
})
export class MapService {
    constructor(
        private http: HttpClient
    ) { }

    getMap(id: string) : Observable<Wishmap> {
        return this.http.get<Wishmap>(`${env.apiUrl}/map/${id}`)
    }

    getSector(mapId: string, sectorNumber: string) : Observable<Sector> {
        return this.http.get<Sector>(`${env.apiUrl}/map/${mapId}/sector/${sectorNumber}`)
    }

    uploadClientPhoto(mapId: string, clientPhoto: Blob) {
        let fd: any = new FormData()

        fd.append('image', clientPhoto)

        return this.http.post<any>(`${env.apiUrl}/map/${mapId}`, fd)
    }

    addSector(mapId:string, sector: any, block: any, text: any, image: Blob) {
        let fd: any = new FormData()

        fd.append('image', image)
        fd.append('sector', sector)
        fd.append('block', block)
        fd.append('text', text)

        return this.http.post<any>(`${env.apiUrl}/map/${mapId}/wishes`, fd)
    }

    addSectorImage(mapId:string, sector: any, block: any, text: any, imageId: string) {
        let fd: any = new FormData()

        fd.append('picture_id', imageId)
        fd.append('sector', sector)
        fd.append('block', block)
        fd.append('text', text)

        return this.http.post<any>(`${env.apiUrl}/map/${mapId}/wishes`, fd)
    }

    updateSector(id:string, sector: any, block: any, text: any, image: Blob) {
        let fd: any = new FormData()

        fd.append('image', image)
        fd.append('sector', sector)
        fd.append('block', block)
        fd.append('text', text)

        return this.http.post<any>(`${env.apiUrl}/wish/${id}`, fd)
    }

    updateSectorImage(id:string, sector: any, block: any, text: any, imageId: string) {
        let fd: any = new FormData()

        fd.append('picture_id', imageId)
        fd.append('sector', sector)
        fd.append('block', block)
        fd.append('text', text)

        return this.http.post<any>(`${env.apiUrl}/wish/${id}`, fd)
    }

    downloadMap(mapId: string, print: boolean) {
        return this.http.post<Blob>(`${env.apiUrl}/map/${mapId}/download`, { to_print: print })
    }

    downloadMapFromCDN(link: string) {
        const httpOptions = {
            responseType: 'blob' as 'json'
        }

        return this.http.get<Blob>(`${link}`, httpOptions)
    }

    getImageCategoryList() {
        return this.http.get<any[]>(`${env.apiUrl}/categories`)
    }

    getImageCategoryImages(id: string) {
        return this.http.get<any[]>(`${env.apiUrl}/pictures/${id}`)
    }
}