import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { HttpClient } from '@angular/common/http'

@Component({
    selector: 'numerology-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    promoCode: string = ''
    text: string = ''

    priceKZT: number = null
    priceUSD: number = null
    priceRUB: number = null
    priceSOM: number = null

    descriptionList: string[] = ['Богатство', 'Слава и репутация', 'Любовь и брак', 'Семья и опора', 'Здоровье', 'Дети и творчество', 'Знания и мудрость', 'Карьера', 'Путешествия']

    hiddenText: boolean = true

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private http: HttpClient,
    ) {
        // check if user owned this map already
        localStorage.getItem('mapId') && this.router.navigate(['/map'])
    }

    ngOnInit(): void {
        this.getPrices()
        this.checkPromoCode()
        this.getText()
    }

    getText() {
        this.http.get('https://api.numerology.kz/fake/text-one')
            .subscribe(res => {
                this.text = res['result'];
            })
    }

    checkPromoCode() {
        this.activatedRoute
            .queryParams
            .subscribe(x => {
                if(x.promo) {
                    localStorage.setItem('promo', x.promo)
                    this.promoCode = x.promo
                } else {
                    let code = localStorage.getItem('promo')
                    if (code) {
                        this.promoCode = code
                    }
                }
            })
    }

    paymentUrl() {
        if (this.promoCode.length === 0) {
            return 'https://numerology.kz/wish/payment'
        } else {
            return `https://numerology.kz/wish/payment?promo=${this.promoCode}`
        }
    }

    getPrices() : any {
        this.http.get('https://api.numerology.kz/price')
            .subscribe(r => {
                this.priceKZT = r['result'].wish.kzt || null
                this.priceUSD = r['result'].wish.usd || null
                this.priceRUB = r['result'].wish.rub || null
                this.priceSOM = r['result'].wish.kgs || null
            })
    }

    toLocaleString(value: any) {
        return parseInt(value).toLocaleString('ru')
    }

    /**
     * Scroll to element
     * @param {Element} el element's id to scroll
     */
    scrollToElement(el: Element) : void {
        el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest'})
    }
}