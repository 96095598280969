import { Component, OnInit, ElementRef } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { Location } from '@angular/common'

import { MapService, ToastService } from '@app/services'
import { Wishmap, Sector } from '@app/models'

import { ImageCroppedEvent, base64ToFile } from 'ngx-image-cropper'

@Component({
    selector: 'numerology-sector',
    templateUrl: './sector.component.html',
    styleUrls: ['./sector.component.scss']
})
export class SectorComponent implements OnInit {
    mapId: string
    map: Wishmap
    sectorId: string
    sectorLoading: boolean = true
    sector: Sector

    selectedBlock: number = 0
    blockText: string = ''

    cropperDisplay: boolean = false
    imageChangedEvent: any = ''
    croppedImage: any = ''
    selectedImage: any

    userCropperDisplay: boolean = false
    userImageChangedEvent: any = ''
    userCroppedImage: any = ''

    hiddenText: boolean = true
    description: string

    badAffirmation: string
    goodAffirmation: string

    galleryDisplay: boolean = false
    imageCategoryList: any
    selectedCategoryName: string
    selectedCategory: any

    galleryAgreed: boolean = false
    galleryAgreedDisplay: boolean = false

    filledDisplay: boolean = false
    initialLoading: boolean = true

    userImage: any = null

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private mapService: MapService,
        private toastService: ToastService,
        private elRef: ElementRef,
        private loc: Location
    ) {
        this.galleryAgreed = localStorage.getItem('galleryAgreed') ? true : false
    }

    ngOnInit(): void {
        this.checkSector()
    }

    goBack() {
        this.loc.back()
    }

    checkSector() {
        this.mapId = localStorage.getItem('mapId')
        this.sectorId = this.activatedRoute.snapshot.params.id
        this.getSector(this.mapId, this.sectorId)
    }

    getMap(mapId: string) {
        this.mapService
            .getMap(mapId)
            .subscribe(map => {
                this.map = map
            })
    }

    getSector(mapId: string, sectorId: string) {
        this.mapService.getSector(mapId, sectorId)
            .subscribe(s => {
                this.getMap(this.mapId)
                this.sector = s
                this.sectorLoading = false
                this.description = s.text
                this.badAffirmation = s.bad_affirmation
                this.goodAffirmation = s.good_affirmation

                if(s.number === 5) {
                    if (s.is_filled) {
                        if(this.map && this.map.clientPhoto) {
                            this.openFilled()
                            this.initialLoading = true
                        }
                    }
                } else {
                    if (s.is_filled) {
                        this.openFilled()
                        this.initialLoading = true
                    }
                }

                if(s.number !== 5) {
                    for (let i = 0; i < this.sector.blocks.length; i++) {
                        if(this.sector.blocks[i] !== null) {
                            if (this.selectedBlock === 5) {
                                return this.selectBlock(5)
                            } else {
                                this.selectedBlock = i + 1
                            }
                        }
                    }
                } else {
                    for (let i = 0; i < this.sector.blocks.length; i++) {
                        if(this.sector.blocks[i] !== null) {
                            if (this.selectedBlock === 3) {
                                return this.selectBlock(7)
                            } else {
                                this.selectedBlock = i + 1
                            }
                        }
                    }
                }
            }, err => {
                this.sectorLoading = false
            })
    }

    getImageCategoryList() {
        this.mapService
            .getImageCategoryList()
            .subscribe(l => {
                this.imageCategoryList = l
            }, err => {
                console.log(err)
                this.toastService.showError(err.error.message ? err.error.message : 'Неизвестная ошибка', 'Ошибка')
            })
    }

    selectImageCategory(id: string, name: string) {
        this.selectedCategoryName = name

        this.mapService
            .getImageCategoryImages(id)
            .subscribe(c => {
                this.selectedCategory = c
            }, err => {
                console.log(err)
                this.toastService.showError(err.error.message ? err.error.message : 'Неизвестная ошибка', 'Ошибка')
            })
    }

    clearCategory() {
        return this.selectedCategory = null
    }

    openGallery() {
        if (this.galleryAgreed) {
            this.getImageCategoryList()
            this.galleryDisplay = true
            document.getElementsByTagName('body')[0].classList.add('modal-open')

            this.elRef.nativeElement.addEventListener('click', el => {
                if (el.target.className === 'sector-gallery-bg') {
                    this.closeGallery()
                }
            })
        } else {
            this.galleryAgreedDisplay = true
            document.getElementsByTagName('body')[0].classList.add('modal-open')

            this.elRef.nativeElement.addEventListener('click', el => {
                if (el.target.className === 'sector-agreed-bg') {
                    this.galleryAgreedDisplay = false
                    document.getElementsByTagName('body')[0].classList.remove('modal-open')
                }
            })
        }
    }

    openFilled() {
        this.filledDisplay = true
        document.getElementsByTagName('body')[0].classList.add('modal-open')

        this.elRef.nativeElement.addEventListener('click', el => {
            if (el.target.className === 'sector-filled-bg') {
                this.closeFilled()
            }
        })
    }

    closeFilled() {
        this.initialLoading = false
        this.filledDisplay = false
        document.getElementsByTagName('body')[0].classList.remove('modal-open')
    }

    submitAgreed() {
        localStorage.setItem('galleryAgreed', 'true')
        this.galleryAgreed = true
        this.galleryAgreedDisplay = false
        document.getElementsByTagName('body')[0].classList.remove('modal-open')
    }

    closeGallery() {
        this.galleryDisplay = false
        document.getElementsByTagName('body')[0].classList.remove('modal-open')
        this.clearCategory()
    }

    selectImage(image: any) {
        this.selectedImage = image
        this.closeGallery()
    }

    clearImage() {
        this.selectedImage = ''
        this.croppedImage = ''
    }

    selectBlock(number: number) {
        this.selectedBlock = number

        this.croppedImage = null
        this.selectedImage = null
        this.blockText = ''

        if(this.selectedBlock !== 7) {
            if(this.sector.blocks[number] !== null) {
                if (this.sector.blocks[number].image) {
                    this.croppedImage = this.sector.blocks[number].image
                }

                if (this.sector.blocks[number].picture) {
                    this.selectedImage = this.sector.blocks[number].picture
                }
                this.blockText = this.sector.blocks[number].text
            }
        }

        this.sectorLoading = true

        setTimeout(() => {
            this.sectorLoading = false
        }, 400)
    }

    userFileChangeEvent(event: any): void {
        this.userImageChangedEvent = event

        this.userCropperDisplay = true
        document.getElementsByTagName('body')[0].classList.add('modal-open')

        this.elRef.nativeElement.addEventListener('click', el => {
            if (el.target.className === 'sector-user-bg') {
                this.userLoadImageFailed()
            }
        })
    }


    userCropperReady() {
        // cropper ready
    }

    userImageLoaded() {
        // display cropper
        this.userCropperDisplay = true
        document.getElementsByTagName('body')[0].classList.add('modal-open')
    }

    userLoadImageFailed() {
        this.userCropperDisplay = false
        document.getElementsByTagName('body')[0].classList.remove('modal-open')
    }

    userImageCropped(event: ImageCroppedEvent) {
        this.userCroppedImage = event.base64;
    }

    clearUserImage() {
        return this.map.clientPhoto = null
    }

    onUserSubmit() {
        this.mapService.uploadClientPhoto(this.mapId, base64ToFile(this.userCroppedImage))
            .subscribe(r => {
                this.userImageChangedEvent = ''
                this.userCroppedImage = ''
                this.userLoadImageFailed()
                this.router.navigate(['/map'])
            }, err => {
                console.log(err)
                this.toastService.showError(err.error.message ? err.error.message : 'Неизвестная ошибка', 'Ошибка')
            })
    }

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event

        this.cropperDisplay = true
        document.getElementsByTagName('body')[0].classList.add('modal-open')

        this.elRef.nativeElement.addEventListener('click', el => {
            if (el.target.className === 'sector-cropper-bg') {
                this.loadImageFailed()
            }
        })
    }

    cropperReady() {
        // cropper ready
    }

    imageLoaded() {
        // display cropper
    }

    loadImageFailed() {
        this.imageChangedEvent = null
        this.croppedImage = null

        this.cropperDisplay = false
        document.getElementsByTagName('body')[0].classList.remove('modal-open')
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64
    }

    onSubmitPhoto() {
        this.cropperDisplay = false
        document.getElementsByTagName('body')[0].classList.remove('modal-open')
    }

    onSubmit() {
        this.mapService.addSector(this.mapId, this.sectorId, this.selectedBlock + 1, this.blockText, base64ToFile(this.croppedImage))
            .subscribe(r => {

                this.imageChangedEvent = ''
                this.croppedImage = ''
                this.selectedImage = ''
                this.blockText = ''

                if(this.sector.number !== 5) {
                    if (this.selectedBlock + 1 === 6) {
                        this.router.navigate(['/map'])
                    } else {
                        this.checkSector()
                    }
                } else {
                    this.checkSector()
                }
            }, err => {
                console.log(err)
                this.toastService.showError(err.error.message ? err.error.message : 'Неизвестная ошибка', 'Ошибка')
            })
    }

    onSubmitUpdate() {
        this.mapService.updateSector(this.sector.blocks[this.selectedBlock].id, this.sectorId, this.selectedBlock + 1, this.blockText, base64ToFile(this.croppedImage))
            .subscribe(r => {

                this.imageChangedEvent = ''
                this.croppedImage = ''
                this.selectedImage = ''
                this.blockText = ''

                this.checkSector()
                this.toastService.showSuccess('Карта желаний успешно обновлена', 'Сектор обновлён')
            }, err => {
                console.log(err)
                this.toastService.showError(err.error.message ? err.error.message : 'Неизвестная ошибка', 'Ошибка')
            })
    }

    onSubmitImage() {
        this.mapService.addSectorImage(this.mapId, this.sectorId, this.selectedBlock + 1, this.blockText, this.selectedImage.id)
            .subscribe(r => {

                this.imageChangedEvent = ''
                this.croppedImage = ''
                this.selectedImage = ''
                this.blockText = ''

                if(this.sector.number !== 5) {
                    if (this.selectedBlock + 1 === 6) {
                        this.router.navigate(['/map'])
                    } else {
                        this.checkSector()
                    }
                } else {
                    this.checkSector()
                }
            }, err => {
                console.log(err)
                this.toastService.showError(err.error.message ? err.error.message : 'Неизвестная ошибка', 'Ошибка')
            })
    }

    onSubmitImageUpdate() {
        this.mapService.updateSectorImage(this.sector.blocks[this.selectedBlock].id, this.sectorId, this.selectedBlock + 1, this.blockText, this.selectedImage.id)
            .subscribe(r => {
                this.imageChangedEvent = ''
                this.croppedImage = ''
                this.selectedImage = ''
                this.blockText = ''

                this.checkSector()
                this.toastService.showSuccess('Карта желаний успешно обновлена', 'Сектор обновлён')
            }, err => {
                console.log(err)
                this.toastService.showError(err.error.message ? err.error.message : 'Неизвестная ошибка', 'Ошибка')
            })
    }
}