<div class="container">
    <footer class="footer">
        <span class="footer-info">
            Карта разработана Диной Кумар
        </span>

        <a class="footer-link" routerLink="/legal/privacy">
            Политика конфиденциальности
        </a>

        <a class="footer-copyright" href="https://garuna.dev" target="_blank">
            <span class="footer-copyright__text">Создали с любовью в</span>
            <svg class="footer-copyright-img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1902 404.9">
                <path class="footer-copyright-img__text" d="M1138.7 82.2c6.7 0 13.1.4 19.1 1.3v58.8c-6.9-4.2-15.8-6.9-27.5-6.9-42.2 0-49 39.1-49 55.4v112.9h-53.1V86.3h44.7l3.8 23.5c8.2-12.7 25.5-27.6 62-27.6zm410.3 0c-36.5 0-53.8 15-61.9 27.6l-3.8-23.5h-44.7v217.4h53.1V190.8c0-16.3 6.8-55.4 49-55.4s49 35.4 49 48.7v119.6h53.1V177.4c-.1-56.9-30.8-95.2-93.8-95.2zm353 4.1v217.4h-44.7l-3.2-19.5c-19.1 14.9-43.1 23.7-69.2 23.7-62.3 0-112.9-50.5-112.9-112.9s50.5-112.9 112.9-112.9c26.1 0 50.1 8.9 69.2 23.7l3.2-19.5h44.7zM1844.6 195c0-33-26.8-59.7-59.7-59.7s-59.7 26.8-59.7 59.7c0 33 26.8 59.7 59.7 59.7s59.7-26.7 59.7-59.7zM940.9 86.3h44.7v217.4h-44.7l-3.2-19.5c-19.1 14.9-43.1 23.7-69.2 23.7-62.3 0-112.9-50.5-112.9-112.9S806.1 82.1 868.5 82.1c26.1 0 50.1 8.9 69.2 23.7l3.2-19.5zM928.2 195c0-33-26.8-59.7-59.7-59.7-33 0-59.7 26.8-59.7 59.7 0 33 26.8 59.7 59.7 59.7 33 .1 59.7-26.7 59.7-59.7zM682.4 86.4h44.7V265c0 65.4-13.2 86.1-29.3 103.6-20.6 22.4-50.2 36.4-83 36.4-39.3 0-81.8-13.9-102-44.4l34.9-35.7c28.6 23.2 43.8 27 67.1 27 15.6 0 30-6 40.8-15.8 20.6-19.1 18.4-48 18.4-48-18.2 12.6-40.2 19.9-64 19.9-62.3 0-112.9-50.5-112.9-112.9S547.6 82.2 610 82.2c26.1 0 50.1 8.9 69.2 23.7l3.2-19.5zm-12.7 108.7c0-33-26.8-59.7-59.7-59.7-33 0-59.7 26.8-59.7 59.7 0 33 26.8 59.7 59.7 59.7 33 .1 59.7-26.7 59.7-59.7zm673.3 4c0 16.3-6.8 55.4-49 55.4s-49-35.4-49-48.7V86.3h-53.1v126.3c0 56.9 30.7 95.2 93.7 95.2 36.5 0 53.8-15 61.9-27.6l3.8 23.5h44.7V86.3h-53v112.8z"/>
                <path class="footer-copyright-img__icon" d="M164.4 0v389.9C71.1 374.1 0 292.8 0 195S71.1 15.8 164.4 0zm66.7 389.9c82.5-14 147.6-79.1 161.6-161.6H231.1v161.6zm80.8-228.3c44.6 0 80.8-36.2 80.8-80.8S356.5 0 311.9 0s-80.8 36.2-80.8 80.8 36.2 80.8 80.8 80.8z" />
            </svg>
        </a>
    </footer>
</div>
