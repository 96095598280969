import { NgModule } from '@angular/core'
import { BrowserModule, Title } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http'

import { IonicModule } from '@ionic/angular'

import { ImageCropperModule as NgxImageCropperModule } from 'ngx-image-cropper'
import { ToastrModule as NgxToastModule } from 'ngx-toastr'

import { AppRoutingModule } from '@app/app-routing.module'
import { AppComponent } from '@app/app.component'

import { HomeComponent, MapComponent, SectorComponent, NotFoundComponent, PrivacyComponent } from '@app/pages'
import { FooterComponent } from '@app/components/footer/footer.component'

@NgModule({
    declarations: [
        AppComponent,
        FooterComponent,
        HomeComponent,
        PrivacyComponent,
        MapComponent,
        SectorComponent,
        NotFoundComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        IonicModule.forRoot(),
        NgxImageCropperModule,
        NgxToastModule.forRoot({
            preventDuplicates: false,
            progressBar: true,
            progressAnimation: 'increasing',
            toastClass: 'numToast',
        }),
    ],
    providers: [Title],
    bootstrap: [AppComponent]
})
export class AppModule { }