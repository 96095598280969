import { Component, OnInit } from '@angular/core'
import { Location } from '@angular/common'

@Component({
    selector: 'numerology-privacy',
    templateUrl: './privacy.component.html',
    styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {
    constructor(
        private loc: Location
    ) { }

    ngOnInit(): void {
    }

    goBack() {
        this.loc.back()
    }
}