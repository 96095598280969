import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'

import {
    HomeComponent,
    MapComponent,
    SectorComponent,
    NotFoundComponent,
    PrivacyComponent
} from '@app/pages'

const routes: Routes = [
    { path: '', pathMatch: 'full', component: HomeComponent },
    { path: 'map', pathMatch: 'full', redirectTo: 'map/' },
    { path: 'map/:id', component: MapComponent },
    { path: 'map/sector/:id', component: SectorComponent },
    { path: 'legal/privacy', component: PrivacyComponent },
    { path: '**', component: NotFoundComponent }
]

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }